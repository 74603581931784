import { useState, useContext, useEffect } from "react";
import landingStyle from "../styles/landing.module.scss";
import LoginFunction from "../utilities/LoginFunction";
import { UserContext } from "../context/UserContext";
import useToken from "../utilities/hooks/useToken";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const { changeUserInformation, userInformation, changelogOutUser } =
    useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remotePassword, setRemotePassword] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const { token, setToken } = useToken();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const testthing = "jsmith@asiatan.com";
  // const name = testthing.split("@")[0];
  // const brand = testthing.slice(testthing.indexOf("@") + 1).split(".")[0];

  // console.log(name, brand);
  const handleLogOutUser = () => {
    changelogOutUser();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const login = LoginFunction(email);
    login.then((response) => {
      if (response.id) {
        setUserInfo(response);
        setToken(response.accessToken);
        setRemotePassword(response.password);
        setMessage("");
        setLoading(false);
      } else {
        setMessage("email not found");
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      userInfo.userType === "vendor" &&
      token?.length > 20 &&
      userInfo.password !== ""
    ) {
      if (password === remotePassword) {
        changeUserInformation({
          userId: userInfo.id,
          userType: userInfo.userType,
          vendor: userInfo.brand,
          userName: userInfo.userName,
          isLoggedIn: true,
        });
        navigate("/library");
        console.log("Vendor with password");
      } else {
        setMessage("");
        if (password.length && password !== remotePassword) {
          setMessage("password incorrect");
        }
      }
    }
    if (
      userInfo.userType === "vendor" &&
      token?.length > 20 &&
      userInfo.password === ""
    ) {
      if (password === remotePassword) {
        changeUserInformation({
          userId: userInfo.id,
          userType: userInfo.userType,
          vendor: userInfo.brand,
          userName: userInfo.userName,
          isLoggedIn: true,
        });
        navigate("/library");
        console.log("Vendor no password");
      }
    }
    if (
      userInfo.userType === "designer" &&
      token?.length > 20 &&
      userInfo.password === ""
    ) {
      changeUserInformation({
        userId: userInfo.id,
        userType: userInfo.userType,
        vendor: userInfo.brand,
        userName: userInfo.userName,
        isLoggedIn: true,
      });
      navigate("/library");
      console.log("designer no password");
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const inputField = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };
  const passwordField = (e) => {
    setPassword(e.target.value);
    setMessage("");
  };

  const { isLoggedIn, userName } = userInformation;

  if (userName && isLoggedIn) {
    return (
      <div className={landingStyle.loginView}>
        <div className={landingStyle.loginViewContent}>
          <h4 className={landingStyle.loginViewHeading}>
            You are currently logged in as {userInformation.userName} from{" "}
            {userInformation.vendor}
          </h4>
          <div onClick={() => navigate("/library")}>go to Library</div>
          <div className="button" onClick={handleLogOutUser}>
            Log out
          </div>
        </div>
        <div
          className="vendor-logos"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(.75)",
            marginTop: "10px",
          }}
        >
          <img src="./images/asiatanLogo.png" alt="asiatan" />
          <img src="./images/tectuffLogo.png" alt="tectuff" />
        </div>
      </div>
    );
  }

  return (
    <div className={landingStyle.loginView}>
      <form className={landingStyle.loginForm}>
        <div className={landingStyle.loginInputView}>
          <input
            type="email"
            placeholder="enter email or creds"
            onChange={inputField}
          />
          {userInfo.userType === "vendor" ? (
            <input
              type="password"
              placeholder="password"
              onChange={passwordField}
            />
          ) : null}
          {message !== "" ? (
            <div className={landingStyle.loginErrorMessage}>{message}</div>
          ) : null}
        </div>
        {loading ? <div className="spinner"></div> : false}
        <button
          className={landingStyle.button}
          type="submit"
          onClick={handleSubmit}
          disabled={!email}
          style={
            !email
              ? { background: "lightgrey", color: "#00000030", cursor: "help" }
              : null
          }
        >
          LOGIN
        </button>
      </form>
      <div
        className="vendor-logos"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(.75)",
          marginTop: "10px",
        }}
      >
        <img src="./images/asiatanLogo.png" alt="asiatan" />
        <img src="./images/tectuffLogo.png" alt="tectuff" />
      </div>
    </div>
  );
}

export default LoginForm;
