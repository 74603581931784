import { Outlet } from "react-router-dom";
import landingStyle from "../styles/landing.module.scss";

function Landing() {
  return (
    <div className="content-full">
      <div className="background-design">{backgroundDesign}</div>
      <div className={landingStyle.loginView}>
        <div className={landingStyle.loginViewContent}>
          <h1>MATLIB</h1>
          <h4>Digital Materials Library</h4>
          <div style={{ width: "inherit" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = [
  { stroke: "#FEFEFE20", fill: "#FEFEFE20" },
  { stroke: "#FEFEFE20", fill: "#FEFEFE30" },

  { stroke: "#FEFEFE30", fill: "#FEFEFE40" },
  { stroke: "#FEFEFE40", fill: "#FEFEFE50" },

  { stroke: "#FEFEFE50", fill: "#FEFEFE60" },
  { stroke: "#FEFEFE60", fill: "#FEFEFE70" },
  { stroke: "#FEFEFE70", fill: "#FEFEFE80" },

  { stroke: "#FEFEFE80", fill: "#FEFEFE90" },
  { stroke: "#33998800", fill: "#33998800" },
  { stroke: "#00ffed90", fill: "#00ffed", transform: "translate(41px, 67px)" },
];

const backgroundDesign = (
  <div
    className="bg-design"
    style={{
      position: "absolute",
      zIndex: -1,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      opacity: 0.3,
      filter: "blur(10px) drop-shadow(#FEFEFE60 3px 2px 11px)",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 768"
      stroke="#FEFEFE20"
    >
      <g>
        <g>
          <path
            style={styles[0]}
            d="M359.7,81.6c7.2,0,14.3,0.5,21.3,1.6l901.5,137.7c31.7,4.8,49.7,18.5,55.9,29.7c3.4,6.1,3.8,12,1.2,18.1
			L1108.9,807c-8.2,19.1-42,33.5-78.6,33.5c-7.2,0-14.3-0.5-21.3-1.6L107.5,701.2c-31.7-4.8-49.7-18.5-55.9-29.7
			c-3.4-6.1-3.8-12-1.2-18.1L281,115.1C289.2,96,323,81.6,359.7,81.6L359.7,81.6 M359.7,65.6c-42.9,0-82.3,17.3-93.3,43.2
			L35.7,647.1c-13.1,30.6,18.1,62.1,69.4,69.9l901.5,137.7c7.9,1.2,15.9,1.8,23.7,1.8c42.9,0,82.3-17.3,93.3-43.2L1354.2,275
			c13.1-30.6-18.1-62.1-69.4-69.9L383.4,67.3C375.4,66.1,367.5,65.6,359.7,65.6L359.7,65.6z"
          />
        </g>
        <g>
          <path
            style={styles[1]}
            d="M299,53.5v16c5.5,0,10.9,0.3,16.3,1l886.1,106.9c14,1.7,27,5.4,37.6,10.9c9.4,4.8,16.5,10.8,20.6,17.2
			c3.7,5.8,4.5,11.4,2.6,17.1l0,0.1L1087.9,761c-5.6,16.9-36,34.3-77.3,34.3c-5.5,0-10.9-0.3-16.3-1L108.2,687.4
			c-14-1.7-27-5.4-37.6-10.9c-9.4-4.8-16.5-10.8-20.6-17.2c-3.7-5.8-4.5-11.4-2.6-17.1l0-0.1l174.3-538.3
			c5.6-16.9,36-34.3,77.3-34.3V53.5 M299,53.5c-44,0-83.6,18.6-92.5,45.3L32.3,637.2c-10.1,30.3,23.3,60.1,74.1,66.1l886.1,106.9
			c6.1,0.7,12.2,1.1,18.2,1.1c44,0,83.6-18.6,92.5-45.3l174.3-538.4c10.1-30.3-23.3-60.1-74.1-66.1L317.2,54.5
			C311.1,53.8,305,53.5,299,53.5L299,53.5z"
          />
        </g>
        <g>
          <path
            style={styles[2]}
            d="M238.4,57.2L238.4,57.2c3.8,0,7.6,0.2,11.3,0.5l870.6,76.2l0.1,0c14.1,1.2,27.3,4.5,38.3,9.6
			c9.9,4.6,17.5,10.3,22.1,16.7c4,5.6,5.4,10.9,4.1,16.4l0,0.2l-118,538.4c-4,16.9-34.2,35.1-76,35.1c-3.8,0-7.6-0.2-11.3-0.5
			L109,673.6l-0.1,0c-14.1-1.2-27.3-4.5-38.3-9.6c-9.9-4.6-17.5-10.3-22.1-16.7c-4-5.6-5.4-10.9-4.1-16.4l0-0.2l118-538.4
			C166.5,75.4,196.6,57.2,238.4,57.2 M238.4,41.2c-45.3,0-85.2,20.1-91.6,47.6l-118,538.5c-7,30.1,28.5,58.1,78.9,62.3l870.6,76.2
			c4.3,0.4,8.5,0.5,12.7,0.5c45.3,0,85.2-20.1,91.6-47.6l118-538.5c7-30.1-28.5-58.1-78.9-62.3L251.1,41.7
			C246.9,41.3,242.6,41.2,238.4,41.2L238.4,41.2z"
          />
        </g>
        <g>
          <path
            style={styles[3]}
            d="M177.8,44.7L177.8,44.7c2.1,0,4.3,0.1,6.4,0.1l855.1,45.4l0.1,0c14.1,0.7,27.6,3.5,39,8.2
			c10.3,4.3,18.6,9.8,23.8,16.1c4.5,5.3,6.4,10.7,5.7,16l0,0.3l-61.8,538.4c-1.2,8.3-8.8,16.9-21,23.6c-14.6,8-33.7,12.4-53.8,12.4
			c-2.1,0-4.3-0.1-6.4-0.1l-855.1-45.4l-0.1,0c-14.1-0.7-27.6-3.5-39-8.2c-10.3-4.3-18.6-9.8-23.8-16.1c-4.5-5.3-6.4-10.7-5.7-16
			l0-0.3L103,80.8c1.2-8.3,8.8-16.9,21-23.6C138.6,49.1,157.7,44.7,177.8,44.7 M177.8,28.7c-46.9,0-87,21.7-90.7,50.1L25.3,617.3
			c-3.9,29.8,33.7,56.1,83.6,58.5l855.2,45.4c2.4,0.1,4.8,0.2,7.2,0.2c46.9,0,87-21.7,90.7-50.1l61.8-538.5
			c3.9-29.8-33.7-56.1-83.6-58.5L185,28.9C182.6,28.8,180.2,28.7,177.8,28.7L177.8,28.7z"
          />
        </g>
        <g>
          <path
            style={styles[4]}
            d="M117.3,32L117.3,32c0.5,0,0.9,0,1.4,0l839.7,14.6l0.1,0c14,0.1,27.7,2.5,39.5,6.8
			c10.8,3.9,19.7,9.3,25.6,15.4c5.1,5.3,7.6,10.6,7.4,16l0,0.3l-5.5,538.4c-0.3,8.2-7.3,16.9-19.1,23.9
			c-14.3,8.4-34.3,13.2-54.8,13.2c-0.5,0-0.9,0-1.4,0L110.5,646l-0.1,0c-14-0.1-27.7-2.5-39.5-6.8c-10.8-3.9-19.7-9.3-25.6-15.4
			c-5.1-5.3-7.6-10.6-7.4-16l0-0.3l5.5-538.4c0.3-8.2,7.3-16.9,19.1-23.9C76.8,36.9,96.8,32,117.3,32 M117.3,16
			c-48.7,0-89.1,23.5-89.9,52.8l-5.5,538.6c-0.9,29.5,38.9,54.1,88.3,54.6L950,676.6c0.5,0,1.1,0,1.6,0c48.7,0,89.1-23.5,89.9-52.8
			l5.5-538.6c0.9-29.5-38.9-54.1-88.3-54.6L118.9,16.1C118.3,16.1,117.8,16,117.3,16L117.3,16z"
          />
        </g>
        <g>
          <path
            style={styles[5]}
            d="M881.1,3c41.5,0,71.8,19,73.1,36.1l0,0.3l50.7,538.4c0.3,5.1-1.8,10.3-6.5,15.5c-5.5,6.1-14,11.5-24.4,15.5
			c-11.5,4.4-24.8,6.9-38.7,7.3l-824.3,16.2l-0.1,0c-1.2,0-2.4,0-3.6,0c-41.5,0-71.9-19-73.1-36.1l0-0.3L-16.4,57.5
			c-0.3-5.1,1.8-10.3,6.5-15.5c5.5-6.1,14-11.5,24.4-15.5c11.5-4.4,24.8-6.9,38.7-7.3L877.4,3.1l0.1,0C878.7,3,879.9,3,881.1,3
			L881.1,3 M881.1-13c-1.3,0-2.7,0-4,0.1L52.8,3.2c-49,1.3-87.3,26.3-85.1,55.6l50.8,538.6c2.1,28.5,41.8,50.9,89.1,50.9
			c1.3,0,2.7,0,4-0.1l824.4-16.2c49-1.3,87.3-26.3,85.1-55.6L970.2,37.9C968.1,9.4,928.4-13,881.1-13L881.1-13z"
          />
        </g>
        <g>
          <path
            style={styles[6]}
            d="M805.1-40.8L805.1-40.8c39.7,0,69.5,17.7,72.5,34.2l0.1,0.3l107,538.5c0.9,5-0.7,10.1-4.7,15.2
			c-10.2,13.1-33.5,22.6-59.3,24.3l-808.9,46.9l-0.1,0c-2.8,0.2-5.7,0.3-8.5,0.3c-39.7,0-69.5-17.7-72.5-34.2l-0.1-0.3l-107-538.5
			c-0.9-5,0.7-10.1,4.7-15.2C-61.5,17.6-38.2,8.1-12.4,6.4l808.9-46.9l0.1,0C799.4-40.7,802.3-40.8,805.1-40.8L805.1-40.8
			 M805.1-56.8c-3.2,0-6.3,0.1-9.5,0.3L-13.4-9.6c-48.6,3.1-84,29.4-78.7,58.4l107,538.7c4.9,27.1,43.6,47.3,88.3,47.3
			c3.2,0,6.3-0.1,9.5-0.3l808.9-46.9c48.6-3.1,84-29.4,78.7-58.4L893.4-9.5C888.5-36.6,849.8-56.8,805.1-56.8L805.1-56.8z"
          />
        </g>
        <g>
          <path
            style={styles[7]}
            d="M729.1-84.9c18.2,0,35.9,3.8,49.7,10.7c12,6,20.1,13.9,22.4,21.7l0.1,0.2l163.3,538.6c1.4,5.1,0.4,10-3,15.2
			c-8.7,13-30.6,23-55.6,25.6l-793.5,77.7l-0.1,0c-4.4,0.5-8.9,0.7-13.4,0.7c-18.2,0-35.9-3.8-49.7-10.7c-12-6-20.1-13.9-22.4-21.7
			l-0.1-0.2L-136.5,34.3c-1.4-5.1-0.4-10,3-15.2c8.7-13,30.6-23,55.6-25.6l793.5-77.7l0.1,0C720.1-84.7,724.6-84.9,729.1-84.9
			L729.1-84.9 M729.1-100.9c-5,0-10,0.3-15.1,0.8L-79.5-22.4c-48.1,5-80.7,32.5-72.4,61.3L11.5,577.5c7.4,25.7,45.2,44,87.5,44
			c5,0,10-0.3,15.1-0.8l793.5-77.7c48.1-5,80.7-32.5,72.4-61.3L816.6-56.9C809.1-82.6,771.4-100.9,729.1-100.9L729.1-100.9z"
          />
        </g>
        <g>
          <path
            style={styles[8]}
            d="M653-129.2c17.4,0,34.5,3.5,48.1,9.8c12.2,5.7,20.7,13.1,23.8,21l0,0.1l219.5,538.7
			c2.1,5.2,1.6,10.3-1.5,15.6c-3.4,5.8-9.5,11.3-17.8,15.9c-9.5,5.2-21.3,9-34.1,10.8L113,591.2c-6,0.9-12.2,1.3-18.3,1.3
			c-17.4,0-34.5-3.5-48.1-9.8c-12.2-5.7-20.7-13.1-23.8-21l0-0.1L-196.7,22.9c-2.1-5.2-1.6-10.3,1.5-15.6
			c3.4-5.8,9.5-11.3,17.8-15.9c9.5-5.2,21.3-9,34.1-10.8l778.1-108.5C640.7-128.7,646.9-129.2,653-129.2L653-129.2 M653-145.2
			c-6.8,0-13.7,0.5-20.6,1.4L-145.6-35.2c-47.7,6.8-77.3,35.6-66,64.1L8,567.6c9.7,24.4,46.5,40.8,86.8,40.8
			c6.8,0,13.7-0.5,20.6-1.4l778.1-108.5c47.7-6.8,77.3-35.6,66-64.1L739.8-104.3C730-128.7,693.3-145.2,653-145.2L653-145.2z"
          />
        </g>
        <g>
          <path
            style={styles[9]}
            d="M90.5,587.6c-36.3,0-68.8-13.8-78.9-33.6L-264.2,15.2c-4-7.8-4-15.7-0.2-23.5c7.4-14.9,28.1-27.2,54.1-31.9
			l762.6-139.2c8-1.5,16.3-2.2,24.6-2.2c36.3,0,68.8,13.8,78.9,33.6l275.8,538.8c4,7.8,4,15.7,0.2,23.5
			c-7.4,14.9-28.1,27.2-54.1,31.9L115.1,585.4C107.1,586.8,98.9,587.6,90.5,587.6z"
          />
          <path
            style={styles[9]}
            d="M577-173.6c16.7,0,33.2,3.2,46.6,9c12.3,5.3,21.2,12.5,25.1,20.2l275.8,538.8c2.8,5.5,2.8,10.8,0.1,16.3
			c-2.8,5.7-8.3,11.1-15.9,15.8c-8.8,5.4-20,9.5-32.5,11.8L113.7,577.5c-7.5,1.4-15.3,2.1-23.2,2.1c-16.7,0-33.2-3.2-46.6-9
			c-12.3-5.3-21.2-12.5-25.1-20.2L-257.1,11.6c-2.8-5.5-2.8-10.8-0.1-16.3c2.8-5.7,8.3-11.1,15.9-15.8c8.8-5.4,20-9.5,32.5-11.8
			l762.6-139.2C561.3-172.9,569.1-173.6,577-173.6L577-173.6 M577-189.6c-8.6,0-17.4,0.8-26.1,2.3L-211.7-48.1
			c-47.2,8.6-74,38.7-59.6,66.9L4.5,557.7c11.8,23,47.6,37.9,86,37.9c8.6,0,17.4-0.8,26.1-2.3L879.2,454c47.2-8.6,74-38.7,59.6-66.9
			L663-151.7C651.2-174.7,615.4-189.6,577-189.6L577-189.6z"
          />
        </g>
      </g>
    </svg>
  </div>
);

export default Landing;
